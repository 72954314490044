import { useContext } from "react";
import {
  CCardBody,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
} from "@coreui/react";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";

import Datacontext from "../components/Datacontext";

const SubCategoryItem = ({
  serviceItem,
  selectedItems,
  setSelectedItems,
  index,
}) => {
  const navigate = useNavigate();
  const { store_id, clientdata } = useContext(Datacontext);
  if (!clientdata) {
    navigate("/");
  }

  const sessionToken = localStorage.getItem("clientsdata");

  const handleServiceSelection = (serviceItem, subCategory) => {
    setSelectedItems((prevItems) => {
      const isItemPresent = prevItems.some(
        (item) => item.service_id === subCategory.id
      );

      if (isItemPresent) {
        return prevItems.filter((item) => item.service_id !== subCategory.id);
      } else {
        return [
          ...prevItems,
          {
            service: serviceItem.service,
            store_id: store_id,
            subcategory: subCategory.name,
            service_id: subCategory.id,
            price: subCategory.price,
            client_id: JSON.parse(sessionToken).id,
          },
        ];
      }
    });
  };

  return (
    <CAccordionItem itemKey={index + 1} key={index}>
      <CAccordionHeader>
        <span className="text-capitalize fw-semibold">
          {serviceItem.service}
        </span>
      </CAccordionHeader>
      <CAccordionBody>
        <div className="card-container row">
          {serviceItem.child?.map((subCat, index) => (
            <div
              key={index}
              className="card-body-container col-md-3 col-sm-12 p-0"
            >
              <CCardBody
                key={index}
                className={`subcategory-card m-1 text-center ${
                  selectedItems.some((item) => item.service_id === subCat.id)
                    ? "active"
                    : ""
                }`}
                onClick={() => handleServiceSelection(serviceItem, subCat)}
              >
                <p>{subCat.name}</p>
              </CCardBody>
            </div>
          ))}
        </div>
      </CAccordionBody>
    </CAccordionItem>
  );
};

export default SubCategoryItem;
