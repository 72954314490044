const ERRORS = {
  INFO: "info",
  ERROR: "error",
  SUCCESS: "success",
  WARNING: "warn",
};

const NO_DATA_ENTER = "No data Entered";
const MISSING_CONTACT = "Missing contact number";
const UNAUTHORIZED = "Unauthorized";
const NOT_MEMBER = "You are not yet the member.";
const NO_SERVER_RESPONSE = "No Server Response";
const MISSING_DATA = "Missing Data";
const NO_DATA_FOUND = "No data found";
const REQUIRED_FIELDS = "Fill all the required fields";
const REGISTERED_SERVICE = "Thank you for registerting the services.";
const FETCH_SERVICE = "Could not fetch services";
const PHONE_LENGTH = "Phone number should have 10 digits";
const BOOKING_ERROR = "Could not make service booking";
const NET_ERROR = "Network Error";
const DATA_ADDED = "Data added successfully";
const DATA_FAILED = "Could not add requested data";
const DATA_UPDATED = "Data updated successfully";
const CAT_SUBCAT = "Please choose category and subcategory";
const ITEM_EXISTS = "This item already exists";
const ERROR_DATA_UPDATE = "Error updating status";
const DATA_ADD_FAILED = "Could not add service";
const NUMERIC_VALUE = "Value must be numeric";
const DISCOUNT_EXCEED = "Discount of more than 20% is not allowed";
const DISCOUNT_EXCEED_PRICE = "Discount amount can't be more than price";
const TABLE_UPDATED = "Table is updated successfully";
const TABLE_UPDATE_FAILED = "Table could not update";
const CORRECT_DISCOUNTS = "Please correct the invalid discounts";
const UPDATE_ERROR = "Could not update item";

export {
  ERRORS,
  NO_DATA_ENTER,
  MISSING_CONTACT,
  UNAUTHORIZED,
  NOT_MEMBER,
  NO_SERVER_RESPONSE,
  MISSING_DATA,
  NO_DATA_FOUND,
  REQUIRED_FIELDS,
  REGISTERED_SERVICE,
  FETCH_SERVICE,
  PHONE_LENGTH,
  BOOKING_ERROR,
  NET_ERROR,
  DATA_ADDED,
  DATA_UPDATED,
  CAT_SUBCAT,
  ITEM_EXISTS,
  ERROR_DATA_UPDATE,
  DATA_ADD_FAILED,
  NUMERIC_VALUE,
  DISCOUNT_EXCEED,
  DISCOUNT_EXCEED_PRICE,
  TABLE_UPDATED,
  TABLE_UPDATE_FAILED,
  CORRECT_DISCOUNTS,
  UPDATE_ERROR,
  DATA_FAILED,
};
