import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CContainer } from "@coreui/react";
import axios from "../api/axios";
import Datacontext from "../components/Datacontext";
import Header from "./parts/Header";
import "./ProfileCard.css";
import SubCategory from "./SubCategory";

import { ERRORS, FETCH_SERVICE } from "../ERROR";
import useError from "../hooks/useError";

const ProfileCard = () => {
  const navigate = useNavigate();
  const { store_id, clientdata, updateClientData } = useContext(Datacontext);
  if (!clientdata) {
    navigate("/");
  }

  const [Services, setItems] = useState([]);
  const sessionToken = localStorage.getItem("clientsdata");

  const { showError } = useError();

  const loadClientData = () => {
    if (clientdata.length === 0) {
      const jsObject = JSON.parse(sessionToken);
      updateClientData(jsObject);
    }
  };

  useEffect(() => {
    fetchData();
    loadClientData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`/getall-services-category/${store_id}`);
      setItems(response.data.Services);
    } catch (error) {
      showError(FETCH_SERVICE, ERRORS.ERROR);
    }
  };

  return (
    <>
      {/* <ToastContainer /> */}
      <Header />
      <CContainer className="service-container mt-5 mb-0">
        <h6>Choose Services</h6>
      </CContainer>
      <CContainer>
        <SubCategory services={Services} />
      </CContainer>
    </>
  );
};

export default ProfileCard;
