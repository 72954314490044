import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "../api/axios";
import useAuth from "../hooks/useAuth";
import DataContext from "../components/Datacontext";
import ServiceTableRow from "./ServiceTableRow";
import {
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
  CCard,
  CCardHeader,
  CCardBody,
  CButton,
  CFormSelect,
  CFormInput,
} from "@coreui/react";

function ServiceSubcategory() {
  const { auth } = useAuth();
  const authToken = auth.access_token;
  const { store_id } = useContext(DataContext);
  const [serviceitems, setServiceItems] = useState([]);
  const [editingItem, setEditingItem] = useState(null);
  const apiURL =
    auth.roles == 6099
      ? `/getall-services-category`
      : `/getall-services-category/${store_id}`;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(apiURL, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setServiceItems(response.data.Services);
    } catch (error) {
      console.error("Error fetching service subcategories:", error);
    }
  };

  const handleEditClick = (index, rowIndex) => {
    // Set the item's editing state to true
    console.log(index + " " + rowIndex);
    setServiceItems((prevItems) =>
      prevItems.map((service, i) =>
        i === index
          ? {
              ...service,
              child: service.child.map((item, j) =>
                j === rowIndex
                  ? { ...item, editing: true }
                  : { ...item, editing: false }
              ),
            }
          : service
      )
    );
  };

  const handleSaveClick = async (index, childIndex) => {
    // console.log("index: "+ index +" childindex: "+ childIndex);
    try {
      const editedItem = serviceitems[index].child[childIndex];
      // Perform the update request with edited data
      if (auth.roles == 6099) {
        await axios.put(`/servicesprice/update/${editedItem.id}`, editedItem, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
      } else {
        await axios.put(`/store-service/update/${editedItem.service_list_id}`,
        {
          visible:editedItem.storeVisible,
          store_price: editedItem.store_price
        }, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
      }
      // Clear the editing state
      setServiceItems((prevItems) =>
        prevItems.map((service, i) =>
          i === index
            ? {
                ...service,
                child: service.child.map((item, j) =>
                  j === childIndex ? { ...item, editing: false } : item
                ),
              }
            : service
        )
      );

      setEditingItem(null);
      // Refetch data to update the UI
      fetchData();
    } catch (error) {
      console.error("Error updating service subcategory:", error);
    }
  };

  const handleCancelClick = (index, rowIndex) => {
    // Clear the editing state for the specific row
    setServiceItems((prevItems) =>
      prevItems.map((service, i) =>
        i === index
          ? {
              ...service,
              child: service.child.map((item, j) =>
                j === rowIndex ? { ...item, editing: false } : item
              ),
            }
          : service
      )
    );
  };

  const handleFieldChange = (parentIndex, childIndex, field, value) => {
    setServiceItems((prevItems) =>
      prevItems.map((service, i) =>
        i === parentIndex
          ? {
              ...service,
              child: service.child.map((item, j) =>
                j === childIndex ? { ...item, [field]: value } : item
              ),
            }
          : service
      )
    );
  };

  return (
    <CCard>
      <CCardHeader>
        <div className="d-flex justify-content-between">
          <h3>Service List</h3>
          <Link className="btn btn-dark" to="/login/service-category-add">
            Add Services
          </Link>
        </div>
      </CCardHeader>
      <CCardBody>
        {serviceitems && serviceitems.length > 0 ? (
          <CAccordion>
            {serviceitems.map((service, index) => (
              <CAccordionItem key={index}>
                <CAccordionHeader>
                  <span className="text-capitalize">
                    <strong>{service.service}</strong>
                  </span>
                </CAccordionHeader>
                <CAccordionBody>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Sno</th>
                        <th>Service</th>
                        <th>Price</th>
                        {auth.roles != 6099 && <th>Store Price</th>}
                        <th>Visible</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {service.child.map((item, rowIndex) => (
                        <tr key={rowIndex}>
                          <td>{rowIndex + 1}</td>
                          <td>
                            {item.editing && auth.roles == 6099 ? (
                              <CFormInput
                                className=""
                                type="text"
                                value={item.name}
                                onChange={(e) =>
                                  handleFieldChange(
                                    index,
                                    rowIndex,
                                    "name",
                                    e.target.value
                                  )
                                }
                              />
                            ) : (
                              item.name
                            )}
                          </td>
                          <td>
                            {item.editing && auth.roles == 6099 ? (
                              <CFormInput
                                type="text"
                                value={item.price}
                                onChange={(e) =>
                                  handleFieldChange(
                                    index,
                                    rowIndex,
                                    "price",
                                    e.target.value
                                  )
                                }
                              />
                            ) : (
                              Number(item.price).toFixed(2)
                            )}
                          </td>

                          {auth.roles != 6099 &&
                            (item.editing ? (
                              <td>
                                <CFormInput
                                  type="text"
                                  value={item.store_price}
                                  onChange={(e) =>
                                    handleFieldChange(
                                      index,
                                      rowIndex,
                                      "store_price",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                            ) : (
                              <td>{Number(item.store_price).toFixed(2)}</td>
                            ))}

                          <td>
                            {auth.roles === 6099 ? (
                              item.editing ? (
                                <CFormSelect
                                  value={item.visible}
                                  onChange={(e) =>
                                    handleFieldChange(
                                      index,
                                      rowIndex,
                                      "visible",
                                      e.target.value
                                    )
                                  }
                                >
                                  <option value="TRUE">TRUE</option>
                                  <option value="FALSE">FALSE</option>
                                </CFormSelect>
                              ) : (
                                item.visible
                              )
                            ) : item.editing ? (
                              <CFormSelect
                                value={item.storeVisible}
                                onChange={(e) =>
                                  handleFieldChange(
                                    index,
                                    rowIndex,
                                    "storeVisible",
                                    e.target.value
                                  )
                                }
                              >
                                <option value="TRUE">TRUE</option>
                                <option value="FALSE">FALSE</option>
                              </CFormSelect>
                            ) : (
                              item.storeVisible
                            )}
                          </td>
                          <td>
                            {item.editing ? (
                              <>
                                <CButton
                                  color="dark"
                                  onClick={() =>
                                    handleSaveClick(index, rowIndex)
                                  }
                                >
                                  Save
                                </CButton>
                                &nbsp;
                                <CButton
                                  color="danger"
                                  onClick={() =>
                                    handleCancelClick(index, rowIndex)
                                  }
                                >
                                  Cancel
                                </CButton>
                              </>
                            ) : (
                              <CButton
                                color="dark"
                                variant="outline"
                                onClick={() => handleEditClick(index, rowIndex)}
                              >
                                Edit
                              </CButton>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </CAccordionBody>
              </CAccordionItem>
            ))}
          </CAccordion>
        ) : (
          <tr>
            <th colSpan="5" scope="row">
              No record found
            </th>
          </tr>
        )}
      </CCardBody>
    </CCard>
  );
}

export default ServiceSubcategory;
